.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-container {
  padding: 20px;
  height: 100vh;
}

.route-container {
  position: relative;
}

.fade-enter {
  opacity: 0;
  transform: translateY(100%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 600ms ease-out, transform 600ms ease-out;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 600ms ease-out, transform 600ms ease-out;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.section {
  min-height: 100vh;
  scroll-snap-align: start;
  scroll-behavior: smooth;
}

.section-heading {
  padding-top: 50px;
  font-size: 2rem;
}

@media (max-width: 700px) {
  .section-heading {
    font-size: 1.5rem;
  }
}

.app-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.app-section.fade-in {
  opacity: 1;
  transform: translateY(0);
}