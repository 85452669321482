.navbar {
    top: 0;
    z-index: 1000;
}

.drawer {
    z-index: 999;
}

.desktop-menu .MuiButtonBase-root {
    padding: 8px 16px;
    font-size: 14px;
    color: inherit;
}